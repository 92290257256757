.mainPopup {
  width: 275px;
  height: auto;
  border: solid 1px #dedede;
  border-radius: 2px;
  padding: 10px;
}

.row {
  display: flex;
  margin: 8px;
}

.popupHeading {
  font-size: 17px;
  color: #707070;
  flex: 4;
  font-weight: 700;
  margin: 5px 0;
}

.rgtStatus {
  font-size: 9px;
  color: #ababab;
  flex: 1.8;
  line-height: 38px;
  margin-left: 2px;
}

.popupFrm .row {
  margin: 10px 0px;
  padding: 0px 10px;
}

.popupFrm label {
  font-size: 10.5px;
  color: #ababab;
  font-weight: 300;
}

.value {
  font-size: 10.5px;
  color: #707070;
  font-weight: 500;
}

.lb1 {
  flex: 1.2;
  min-width: 75px;
  max-width: 75px;
}

.lb2 {
  flex: 1.5;
  min-width: 75px;
  max-width: 75px;
}

.lb3 {
  flex: 1.2;
  min-width: 75px;
  max-width: 75px;
}

.vl1 {
  flex: 2.8;
  min-width: 180px;
  max-width: 180px;
}

.vl2 {
  flex: 1;
}

.vl3 {
  flex: 2.8;
  display: inline-block;
}

.popupSelect {
  border: solid 1px #cbcbcb;
  border-radius: 3px;
  height: 27px;
  text-indent: 5px;
  line-height: 27px;
  outline: none;
  color: #949494;
  width: 100%;
  font-size: 11px;
}

.selLabel {
  line-height: 32px;
}

.popupTextarea {
  border: solid 1px #cbcbcb;
  height: 80px;
  background: #fff;
  font-family: 'Roboto', sans-serif;
  color: #949494;
  line-height: 22px;
  text-indent: 5px;
  border-radius: 4px;
  font-size: 11px;
  outline: none;
}

.btPopup {
  width: 101px;
  height: 28px;
  font-size: 12px;
  color: #707070;
  text-align: center;
  line-height: 28px;
  background: #fff;
  border: none;
  outline: none;
  box-shadow: 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
}
.submitBt {
  background: #ffd2d2;
}

.bt1 {
  flex: 4;
}

.bt2 {
  flex: 1;
}

.errorText {
  /* composes: statusRed from '../../cssModules/Colors.module.css'; */
  color: red;
  font-size: 13px;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .mainPopup {
    width: 90%;
    margin: 0 auto;
  }
  .rgtStatus {
    font-size: 8px;
  }
}

.mightOverflow {
  /* composes: mightOverflow from '../../cssModules/Tables.module.css'; */
}
