/* .container {
    width: 100%;
    height: 100%;
    background-color: rgb(243, 245, 249);
  } */
  
  .container {
    height: 100%;
    width: 100%;
    background-color: rgb(243, 245, 249);

    /* margin-top: 0.8em; */
    display: flex;
    flex-direction: column;
  }
  .tableView {
    position: relative;
    overflow: hidden;
  }
  

  .processHeader{
    display: flex;
  height: 3em;
  justify-content: space-between;
}


.topDiv{
  justify-content: space-between;
  padding: 0 2em;
  padding-right: 2em;
  flex-grow: 1;
  align-items: center;
}


  
  .backButtonLastRefreshedDateWrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 2em;
    padding-right: 2em;
    flex-grow: 1;
    align-items: center;
  }
  
  .lastUpdatedAtText {
    font-size: 0.68vw;
    display: block;
    text-align: right;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    padding-top: 1em;
  }
  


  .actionButton:active {
    background: #e28404;
  }

  
 .actionButton {
    font-size: 0.7em;
    padding: 0.5em 0.7em;
    letter-spacing: 1.25px;
    margin-right: 1em;
    text-transform: uppercase;
  }
   
  .colorYes{
    color: #48b275;
  }
  .colorNo{
   color: #f17c7c
  }
  .topBar{ 
    opacity: 1;
    flex-grow: 1;
    margin-left: 20px;
    padding: 10px 15px;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    color: #146DA2;
}
  .processTopbar{
    background: 0% 0% no-repeat rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;
    border-radius: 4px;
    opacity: 1;
    flex-grow: 1;
    margin-left: 20px;
    padding: 10px 15px;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    color: #146DA2;
    
  }
  .divspan{
    margin: 0.1em;
      margin-left: 1.2em;
      font-size: 16px/23px;
      font-weight: bold;
      text-align: left;
      letter-spacing: 0em;
      color: #4e4e4e;
      color: #2E75B5;
      opacity: 1;
   }
   .divspan span{
    margin: 0.1em;
      margin-left: 0.2em;
      text-align: left;
      letter-spacing: 0em;
      color: #4e4e4e;
      color: #2E75B5;
      opacity: 1;
      font-weight: lighter;
  
  
   }
    