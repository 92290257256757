/* .container {
    width: 100%;
    height: 100%;
    background-color: rgb(243, 245, 249);
  } */
  
  .container {
    height: 100%;
    width: 100%;
    background-color: rgb(243, 245, 249);

    /* margin-top: 0.8em; */
    display: flex;
    flex-direction: column;
  }
  .tableView {
    /* position: relative; */
    /* overflow: hidden; */
  }
  
  .time {
    font-size: 1em;
    font-weight: normal;
  }
  
  .date {
    font-size: 11px;
    font-weight: normal;
  }
  
  .processHeader{
    display: flex;
  height: 3em;
  justify-content: space-between;
}
.formContainer {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000027;
  opacity: 1;
  padding: 0.5em 0.5em 0 0.5em;
  border-radius: 4px;
  margin-top: 0.8em;
  flex-grow: 1;
  /* overflow: auto; */
  margin-left: 31px;
    margin-right: 31px;
}
.sel1 {
    width: 39%;
  
}

.select {
  color: #707070;
  font-weight: 500;
  font-size: 1em;
  border-radius: 2px;
  display: inline-block;
  border: none;
  outline: none !important;
  box-shadow: none !important;
 
}
.select:hover {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}
.rowDiv{
  width: 100%;
  display: flex;
}
.formDiv{
  width: 100%;
  display: flex;
}
.sel2 {
  width: 100%;
} 

.formGroup {
  /* padding: 1.5em */
  padding-left: 1.5em;
  padding-right: 1.5em;
  padding-top: 0px;
  padding-bottom: 5px;
}
.formGroupRow {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 1em 0;
  column-gap: 1em;
}
.formRow {
  display: flex;
  align-items: center;
  font-size: 14px;
  width: 100%;
  gap: 0.5em;
  justify-content: space-between;
  min-width: 33.33%;
}
.label {
  /* font-size: 14px;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  margin-right: 10px;
  width: 40%; */

  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-24) var(--unnamed-font-family-open-sans);
letter-spacing: var(--unnamed-character-spacing-0-1);
text-align: left;
font: normal normal bold 14px/24px Open Sans;
letter-spacing: 0.1px;
color: #4E4E4E;
opacity: 1;
}
.myclass {
  width: 100% !important;
}
.topDiv{
  justify-content: space-between;
  padding: 0 2em;
  padding-right: 2em;
  flex-grow: 1;
  align-items: center;
}


  
  .tabAndDatePickerWrapper {
    display: flex;
    height: 6.5vh;
    max-height: 6.5vh;
    justify-content: space-between;
    padding: 0 2em;
    flex-grow: 1;
    
  }
  
  .backButtonLastRefreshedDateWrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 2em;
    padding-right: 2em;
    flex-grow: 1;
    align-items: center;
  }
  
  .lastUpdatedAtText {
    font-size: 0.68vw;
    display: block;
    text-align: right;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    padding-top: 1em;
  }
  
  .shift {
    display: block;
    text-align: center;
    color: #2e75b5;
    width: 22px;
    height: 22px;
    line-height: 22px;
    border-radius: 5px;
    margin-top: -4px;
    margin-left: 10px;
    border: 2px solid #2e75b5;
    font-weight: bold;
  }
  
  .ellipsisText {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .btn{

    top: 437px;
left: 144px;
width: 105px;
height: 35px;
/* UI Properties */
background: var(--unnamed-color-82c1a7) 0% 0% no-repeat padding-box;
background: #82C1A7 0% 0% no-repeat padding-box;
border-radius: 4px;
opacity: 1;
  }
  .actionButton:active {
    background: #e28404;
  }
  .primaryActionButton {
    float: right;
    margin-right: 0;
    font-size: 0.7em;
    padding: 0.5em 0.7em;
  }
  
  .primaryActionButton:active {
    background: #488f71;
  }
  
  .actionButton {
    font-size: 0.7em;
    padding: 0.5em 0.7em;
    letter-spacing: 1.25px;
    margin-right: 1em;
    text-transform: uppercase;
  }
  .btnDelete{
    background: #F17C7C 0% 0% no-repeat padding-box !important;
  }


  .lableDiv{
    width: 40%;
    margin-top: 9px;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 14px;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    word-break: break-all;
    height: 20px;
  }
  .rowDiv{
    width: 100%;
    display: flex;
  }
  .formDiv{
    width: 100%;
    display: flex;
  }
  .sel2 {
    width: 100%;
  } 
  /* bok */
  .popContainer {
    width: 470px;
    padding: 50px 40px;
    margin: 0 auto;
    border-radius: 0;
  }
  .popupBox {
    border-radius: 0;
    width: 470px !important;
  }
  .content {
    margin-top: -15px;
    margin-bottom: 5px;
  }
  .title {
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    display: block;
    margin: -16px -24px 10px -24px;
    padding: 12px;
    background: #F17C7C;
    display: flex;
    align-items: center;
  }

  .actionButton {
    font-size: 0.7em;
    padding: 0.5em 0.7em;
    letter-spacing: 1.25px;
    margin-right: 1em;
    text-transform: uppercase;
  }
  .resetButton {
    color: #fff;
    background: #C96B6B;
  }
  
  .resetButton:hover {
    background: #b45a5a;
  }
  .primaryActionButton {
    float: right;
    margin-right: 0;
    font-size: 0.7em;
    padding: 0.5em 0.7em;
  }
  
  .primaryActionButton:active {
    background: #488f71;
  }
  .error {
    color: #FFA222;
    font-size: 14px;
  }
  .transparentButton {
    color: #2E75B5;
    background: transparent;
    box-shadow: none;
  }
  
  .transparentButton:active {
    background: transparent;
  }

  .topBar{ 
    opacity: 1;
    flex-grow: 1;
    margin-left: 20px;
    padding: 10px 15px;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    color: #146DA2;
}
  
  .processTopbar{
    background: 0% 0% no-repeat rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;
    border-radius: 4px;
    opacity: 1;
    flex-grow: 1;
    margin-left: 20px;
    padding: 10px 15px;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    color: #146DA2;
    
  }
  .divspan{
    margin: 0.1em;
      margin-left: 1.2em;
      font-size: 16px/23px;
      font-weight: bold;
      text-align: left;
      letter-spacing: 0em;
      color: #4e4e4e;
      color: #2E75B5;
      opacity: 1;
   }
   .divspan span{
    margin: 0.1em;
      margin-left: 0.2em;
      text-align: left;
      letter-spacing: 0em;
      color: #4e4e4e;
      color: #2E75B5;
      opacity: 1;
      font-weight: lighter;
  
  
   }