/* .listView {
  background: #eaeff7;
  border: solid 1px #d8d8d8;
  box-shadow: 1px 1px 5px #00000030;
  padding: 15px 20px;
  width: 22vw;
  border-radius: 16px;
  height: 100%;
  box-sizing: border-box;
  margin-right: 3%;
}
.listTitle {
  color: #4e4e4e;
  font-size: 15px;
  font-weight: 600;
  display: block;
  margin-bottom: 14px;
  text-transform: uppercase;
}
.listItem {
  width: 100%;
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 600;
  color: #4e4e4e;
  text-transform: uppercase;
  cursor: pointer;
  background: #fff;
  border-radius: 6px;
  box-shadow: 1px 2px 2px #00000030;
  padding: 17px 12px;
  border: none;
  outline: none;
  text-align: left;
  transition: 0.2s;
}
.listItem:hover {
  background: #FFA222;
  color: #fff;
}

.selected {
  background: #FFA222;
  color: #fff;
  opacity: 0.6;
  pointer-events: none;
  box-shadow: none;
}

.selectedLane {
  background-color: transparent;
  box-shadow: none;
  color: #4e4e4e7a;
} */

/* .listView {
  background: #eaeff7;
  border: solid 1px #d8d8d8;
  box-shadow: 1px 1px 5px #00000030;
  padding: 10px 0px;
  width: 22vw;
  border-radius: 16px;
  height: 100%;
  box-sizing: border-box;
  margin-right: 3%;
  overflow: hidden;
} */

.listView { 
  background: #eaeff7; 
  border: solid 1px #d8d8d8; 
  box-shadow: 1px 1px 5px #00000030; 
  padding: 15px 20px; 
  width: 22vw;  
  border-radius: 16px; 
  /* height: 72vh;  */
  height: 77vh;

  box-sizing: border-box; 
  margin-right: 3%; 
  overflow: auto;

}
.scrollContainer {
  padding: 0 20px;
  overflow: auto;
  height: 100%;
}
.listTitle {
  color: #4e4e4e;
  font-size: 15px;
  font-weight: 600;
  display: block;
  margin-bottom: 14px;
  text-transform: uppercase;
}
.listItem {
  width: 100%;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #4e4e4e;
  cursor: pointer;
  background: #fff;
  border-radius: 6px;
  box-shadow: 1px 2px 2px #00000030;
  padding: 17px 12px;
  border: none;
  outline: none;
  text-align: left;
  transition: 0.2s;
}
.listItem:hover {
  background: #4A70BD;
  color: #fff;
}
.selected {
  background: #4A70BD;
  color: #fff;
  opacity: 0.6;
  pointer-events: none;
  box-shadow: none;
}
.selectedLane {
  background-color: transparent;
  box-shadow: none;
  color: #4e4e4e7a;
}
.scrollBar {
  overflow-y: auto !important;
}
.scrollBar::-webkit-scrollbar {
  width: 4px !important;
  height: 9px !important;
}
.scrollBar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px #dddddd;
  border-radius: 1px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-top: 4px solid transparent;
}
.scrollBar::-webkit-scrollbar-thumb {
  background: #2162ac;
  border-radius: 4px;
  background-clip: padding-box;
}
.scrollBar::-webkit-scrollbar-thumb:hover {
  background: #194c86;
}