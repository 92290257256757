.mightOverflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ellipsisPopup {
  padding: 5px 5px;
}

.header {
  background: #F0F0F0 0% 0% no-repeat padding-box;
  margin: 0;
  padding: 0.5em;
  text-align: left;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  font-size: 1em;
  font-weight: bold;
}