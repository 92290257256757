.backButton {
  background-color: transparent;
  text-transform: none;
  font-weight: normal;
  font-size: 1.15vw;
  height: 30px;
  border: none;
  outline: none;
  border-radius: 0px;
  box-shadow: 0px 0px 0px transparent;
  text-transform: none;
  padding: 0;
  margin-bottom: 0.5em;
}

.backButton:hover {
  background: transparent;
}

.arrowBackIcon {
  color: #e8eef6;
  opacity: 1;
  width: 1em;
  height: 1em;
  background: #2162ac;
  border-radius: 20px;
}

.materialGroup {
  display: inline-block;
  margin-left: 0.5em;
  color: #0275a7;
  text-align: left;
  font-size: 1.15vw;
  letter-spacing: 0em;
  color: #2162ac;
}

.action {
  text-align: left;
  letter-spacing: 0em;
  color: #2162ac;
  font-size: 1.15vw;
  font-weight: bold;
  margin-left: 0.5em;
}
