.headerWithMenu {
  display: flex;
  width: 100%;
  align-items: center;
}
HeaderWithMenu
.headerWithMenu.ellipsisText {
  flex-grow: 1;
}

.filterIcon {
  flex-grow: 0;
  /* color: #E9DEAE; */
  color: #0ea5e6;

  font-size: 1.2em;
  cursor: pointer;
  margin-left: 1em;
}

.filterIcon:hover {
  color: #fff;
  background-color: #E9DEAE;
  border-radius: 5px;
}

.filterIcon:active {
  color: #fff;
  background-color: #E9DEAE;
  border-radius: 5px;
}

.openFilterIcon {
  color: #fff;
  background-color: #E9DEAE;
  border-radius: 5px;
}

.ellipsisText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* ellipis popover -------------------------- */

.popoverPaper {
  color: white;
  background-color: grey;
  font-size: 11.8px;
  padding: 3px 6px;
}

.popover {
  pointer-events: none;
}
/* ------------------------------------------ */