.container {
  height: 100%;
  padding: 0 1em 1em 1em;
  box-sizing: border-box;
  /* background-color: #f3f5f9; */
  background-color: #FFFFFF;

  display: flex;
  flex-direction: column;
 
}

.columnsContainer {
  flex-grow: 1;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  /* background-image: url("./car.png");  */
   background-repeat: no-repeat;
   /* background-position: right; */


}
.centerIMg{
  background-position: center;

}
.rightIMg{
  background-position: right;

}

.columnsContainerLeft {
  display: flex;
}
