.processToolbar {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.formRow {
  display: flex;
  align-items: center;
  font-size: 0.9em;
  gap: 0.5em;
}

.label {
  font-size: 1em;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  font-weight: bold;
}

.select {
  color: #707070;
  font-weight: 500;
  font-size: 0.9em;
  border-radius: 2px;
  display: inline-block;
  border: none;
  outline: none !important;
  box-shadow: none !important;
  width: 8em;
}

.select:hover {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

.sel1 {
  width: 16em;
}

.hoverWrapper {
  position: relative;
}

.hoverText{
  position: absolute;
  left: 0;
  right: 0;
  top: 110%;
  height: auto;
  background: #707070;
  color: #fff;
  font-size: 0.9em;
  padding: 0.5em;
  border-radius: 4px;
  text-align: left;
  box-shadow: 0px 4px 5px rgb(0 0 0 / 30%);
}