.container {
    width: 100%;
    height: 100%;
    padding: 0 2em;
    padding-top: 0.5em;
    background-color: rgb(243, 245, 249);
    display: flex;
    flex-direction: column;
    color: #707070;
  }
.backButtonLastRefreshedDateWrapper {
display: flex;
justify-content: space-between;
align-items: center;
}
.processHeader{
    display: flex;
  height: 3em;
  /* justify-content: space-between; */
  justify-content: unset;
}
.pageTitle{
    background: #EFF7FF;
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;
    border-radius: 4px;
    color: #707070;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.processTopbar{
  background: 0% 0% no-repeat rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;
  border-radius: 4px;
  opacity: 1;
  flex-grow: 1;
  margin-left: 20px;
  padding: 10px 15px;
  align-items: center;
  display: flex;
  height: 29px;
}
.selectBox{
    max-width: 118px;
    width: 100%;
    margin-left: 20px;
    height: 28px;
    color: #707070;
    border: 1px solid #CDD4E0;
    background: #f0f0f0;
    border-radius: 3px;
}
.processForm{
    background: #fff;
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;
    border-radius: 4px;
    padding: 15px;
    margin-top: 15px;
}
.menuTitle{
    font-size: 14px;
    background-color: #f0f0f0;
    padding: 10px 10px;
}
.tableView {
    position: relative;
    overflow: hidden;
  }
  .title {
    margin: 0.1em;
    margin-left: 0.2em;
    font-size: 16px/23px;
    font-weight: bold;
    text-align: left;
    letter-spacing: 0em;
    color: #4e4e4e;
    opacity: 1;
  }
  .Adobtitle{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-24) var(--unnamed-font-family-open-sans);
letter-spacing: var(--unnamed-character-spacing-0-1);
color: #2E75B5;
text-align: left;
font: normal normal bold 14px/24px Open Sans;
letter-spacing: 0.1px;
color: #2E75B5;
opacity: 1;

  }

  .spanTitle{
    margin-right: 35px;
  }

  .lableDiv{
    width: 40%;
    margin-top: 9px;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 14px;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    word-break: break-all;
    height: 20px;
  }
  .rowDiv{
    width: 100%;
    display: flex;
  }
  .formDiv{
    width: 100%;
    display: flex;
  }
  .sel2 {
    width: 100%;
  } 

  /* pratik */
  