.content {
  padding: 1em;
  background: #FFFBE6;
  overflow-x: auto;
}

table {
  min-height: 8em;
  width: 100%;
  border-collapse: collapse;
  font-size: 1em;
  table-layout: fixed;
}

table th, table td {
  padding: 0.5em;
  font-size: 1em;
}

.documentsContainer {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 4px;
  opacity: 1;
}

.documentsContainer tr:first-child td:first-child {
  border-top-left-radius: 4px;
}

.documentsContainer tr:last-child td:first-child {
  border-bottom-left-radius: 4px;
}

.documentsContainer tr:first-child td:last-child {
  border-top-right-radius: 4px;
}

.documentsContainer tr:last-child td:last-child {
  border-bottom-right-radius: 4px;
}
.contentWrapper {}