.listContainer {
  height: 100%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  opacity: 1;
}

.list {
  padding: 0.8em 0.6em;
}

.listItem {
  background: #FFFFFF;
  border-radius: 4px;
  opacity: 1;
  margin: 0.5em 0.2em;
  height: calc(100% - 1em);
}

.selected {
  outline: 3px solid #3C83CB;
 }

.unSelected {
  box-shadow: 0px 3px 6px #00000029;
}

.listItemHeader {
  background: #7E92A7;
  opacity: 1;
  padding: 0.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
 
}
.listItemHeader1 {
  background: #006EDE;
  opacity: 1;
  padding: 0.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}

.title {
  font-size: 0.9em;
  text-align: left;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 0.8;
  font-weight: 500;
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.state {
  font-size: 0.75em;
  letter-spacing: 0px;
  color: #FFFFFF;
  background: #F9B233 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  padding: 0.1em 0.5em;
}

.terminate {
 background: #F17C7C; 
}
.complete {
  background: #18A523; 
 }

.state span {
  opacity: 0.77;
}

.listItemBody {
  padding: 0.5em;
 
}

.partNumber {
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  font-size: 0.75em;
  font-weight: 600;
}

.commodity {
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  font-size: 0.8em;
}

.supplierCode {
  margin-top: 0.7em;
  letter-spacing: 0.5px;
  color: #006EDE;
  font-size: 0.90em;
}
.inputWrapper{
  border: 2px solid #0275a7;
  border-radius: 5px;
  display: flex;
  padding: 5px 0;
  justify-content: space-between;
}
.inputBox{
  border: none;
  padding: 0 5px;
  width: 100%;
}
.inputBox:focus{border: none;outline: none;}
.listWrapper{height: calc(100% - 4.5em);}
.searchIcon {font-size: 20px;color: #0275a7;padding-left: 10px;}