/* .toolListView {
  background: #eaeff7;
  border: solid 1px #d8d8d8;
  box-shadow: 1px 1px 5px #00000030;
  padding: 15px 20px;
  width: 22vw;
  border-radius: 16px;
  box-sizing: border-box;
  height: 100%;



} */

.toolListView {

  background: #eaeff7;

  border: solid 1px #d8d8d8;

  box-shadow: 1px 1px 5px #00000030;

  padding: 15px 20px;

  width: 22vw;

  border-radius: 16px;

  height: 72vh;

  box-sizing: border-box;

  margin-right: 3%;

  overflow: auto;

}

.toolListTitleContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 14px;
}

.toolListTitle {
  color: #0275a7;
  font-size: 15px;
  font-weight: 600;
  display: block;
  text-transform: uppercase;
  margin-left: 0.6em;
}

.toolListItem {
  width: 100%;
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 600;
  color: #4e4e4e;
  text-transform: uppercase;
  cursor: pointer;
  background: #fff;
  border-radius: 6px;
  box-shadow: 1px 2px 2px #00000030;
  padding: 12px 12px;
  border: none;
  outline: none;
  text-align: left;
  transition: 0.2s;
}

.toolListItem:hover {
  background: #FFA222;
  color: #fff;
}

.toolListItem span {
  line-height: 24px;
}

.toolListItem img {
  float: right;
  width: 20px;
}

.toolListItem img:hover {
  color: #fff;
}
