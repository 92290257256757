.button {
  padding: 0.5em;
  display: block;
  /* TODO: export to theme */
  color: #0380af;
  position: relative;
}
.tooltip::before {
  background-color: #707070;
  border: 1px solid #707070;
  border-radius: 4px;
  color: #fff;
  content: attr(description);
  display: none;
  font-size: 11px;
  padding: 4px 10px;
  position: absolute;
  top: 8px;
  left: -60px;
  z-index: 100;
  text-transform: none;
}
.tooltip:hover::before {
  display: block;
}
