.actionButton {
  font-size: 0.7em !important;
  padding: 0.5em 0.7em !important;
  letter-spacing: 1.25px;
  margin-right: 1em;
  text-transform: uppercase;
}

.primaryActionButton {
  float: right;
  margin-right: 0;
  font-size: 0.7em;
  padding: 0.5em 0.7em;
}

.primaryActionButton:active {
  background: #488f71;
}