.selectionMenuContainer {
  width: 14em;
  height: auto;
  overflow: hidden;
  box-sizing: border-box;
  background: #fafafa;
  border: none;
}

.selectionMenu {
  overflow: scroll !important;
  height: 10em;
  width: 100%;
  margin-top: 10px;
}

.selectOption {
  display: flex;
  align-items: center;
  margin-top: 0.5em;
}

.selectOption > input {
  margin-right: 0.5em;
}

.selectOption > label {
  font-size: 14px;
  color: #4e4e4e;
}

.okButton {
  display: relative;
  padding-left: 0.5px;
  padding-right: 0.5px;
  min-width: 13%;
  width: 13%;
  height: 3%;
  margin: 0.5em;
  font-size: 0.8em;
  background-color: #82c1a7;
  color: #eff7ff;
}

.cancelButton {
  display: relative;
  font-size: 0.8em;
  height: 3.5%;
  margin-left: 1em;
  padding-left: 1px;
  padding-right: 1px;
  background: transparent;
  box-shadow: none;
  color: #2e75b5;
}

.okButton:hover {
  background-color: #82c1a7;
}

.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.resetToDefaultButton {
  display: relative;
  padding: 5px 1em;
  max-width: 40%;
  font-size: 14px;
  color: #0075a7;
  margin-left: 11em;
  background: transparent;
  box-shadow: none;
}

.scrollBar {
  composes: scrollBar from '../../../../cssModules/ScrollBar.module.css';
}
