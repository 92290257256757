
  .title {
    color: #146DA2;
    font-weight: 500;
    font-size: 18px;
    display: block;
    margin: -16px -24px 10px -24px;
    padding: 12px;
    background: #eff7ff 0% 0% no-repeat padding-box;
    display: flex;
    align-items: center;
  }
  
  .txt {
    padding-left: 12px;
    font-weight: 600;
    display: inline-block;
  }
  
  .content {
    margin-top: -15px;
    margin-bottom: 5px;
  }
  .primaryActionButton {
    float: right;
    margin-right: 0;
    font-size: 14px;
    padding: 0.5em 0.7em;
  }
  
  .primaryActionButton:active {
    background: #488f71;
  }
  
  .transparentButton {
    color: #2E75B5;
    background: transparent;
    box-shadow: none;
    font-size: 14px;
  }
  
  .transparentButton:active { 
    background: transparent;
  }
  .mainTitle{
    margin: 0 0 20px;
    color: #146DA2;
    font-size: 16px;
  }
  .formRow{
    display: flex;
    align-items: baseline;
  }
  .label{margin-right: 10px;}
  .uploadFileInput {
    position: absolute;
    z-index: 1000;
    opacity: 0;
    cursor: pointer;
    right: 0;
    top: 0;
    height: 100%;
    font-size: 0;
    width: 100%;
    cursor: pointer;
  }
  .deleteIcon, .blueText {
    color: #146DA2;
  }
  .templateBlock{
    display: flex;
    flex-grow: 3;
    justify-content: space-between;
    align-items: center;
  }