.select {
  width: 180px;
  height: 32px;
  border-radius: 4px;
  color: #707070;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  margin-left: 15px;
}

.dropDownIcon {
  font-size: 20px;
}

.dropDownIconButton {
  color: rgb(61, 157, 246);
  border-radius: 3px;
  background-color: #fff;
  width: 20px;
  height: 25px;
  box-shadow: none;
  padding: 2px;
  min-width: 0;
  min-height: 0;
  box-sizing: border-box;
}

.dropDownIconButton:hover {
  background-color: #fff;
  color: rgb(52, 133, 209);
}
