.baseContainer {
  display: flex;
  flex-direction: column;
  padding-top: 12px;
}

.basicTableContainer {
  flex-grow: 1;
}

.basicTable {
  box-shadow: 0px 3px 6px #00000027;
  background: #fff;
  border-radius: 5px;
}

.tableHeader::-webkit-scrollbar {
  width: 4px !important;
  height: 0 !important;
}

.tableHeader > div {
  padding: 0 11px;
  box-sizing: border-box;
  overflow: hidden;
}

/* --------------------------------------- */

/* copy alert ------------------------------ */
.copyAlert {
  padding: 0.5em 1em;
  border-radius: 5px;
  position: absolute;
  bottom: 3em;
  left: 2em;
  color: #fff;
  background-color: #82c1a7;
  z-index: 10;
}

.visible {
  visibility: visible;
  opacity: 1;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}
/* ----------------------------------------- */

/* actions bar ----------------------------- */
.actionsBar {
  flex-grow: 0;
  height: auto;
  margin: 0.7em 0;
  width: 100%;
  padding: 3.8px 0;
  box-sizing: border-box;
}




.cutomizeTableButton {
  color: #fff;
  background: #FFA222;
}

.cutomizeTableButton:hover {
  background: #e28404;
}
.btnDelete{
  background: #F17C7C 0% 0% no-repeat padding-box !important;
}
.actionButton:active {
  background: #e28404;
}
.primaryActionButton {
  float: right;
  margin-right: 0;
  font-size: 0.7em;
  padding: 0.5em 0.7em;
}

.primaryActionButton:active {
  background: #488f71;
}

.actionButton {
  font-size: 0.7em;
  padding: 0.5em 0.7em;
  letter-spacing: 1.25px;
  margin-right: 1em;
  text-transform: uppercase;
}

.clearFiltersButton {
  color: #FFA222;
  background: transparent;
  box-shadow: none;
}

.clearFiltersButton:active {
  background: transparent;
}



/* ------------------------------------------ */

/* ellipis popover -------------------------- */

.popoverPaper {
  color: white;
  background-color: grey;
  font-size: 11.8px;
  padding: 3px 6px;
}

.popover {
  pointer-events: none;
}
/* ------------------------------------------ */

.asnsCount {
  background-color: #0275a7;
  width: auto;
  height: 23px;
  color: #fff;
  padding: 3px 3px;
  box-sizing: border-box;
  border-radius: 5px;
  display: inline-block;
}

.scrollBar {
  composes: scrollBar from '../../cssModules/ScrollBar.module.css';
}
