/* .tableHeader {
  width: auto;
  max-width: 100%;
  padding-top: 0.5em;
  padding-left: 0.1em;
  box-sizing: border-box;
  white-space: nowrap;
  font-size: 14px;
  font-weight: bold;
  color: #5c5b5b;
  padding-right: 5px;
  overflow-x: scroll;
} */

.scrollBar::-webkit-scrollbar {
  display: none;
}

.tableHeader {
  width: auto;
  max-width: 100%;
  padding: 0.5em 0;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  font-weight: bold;
  color: #5c5b5b;
  /* box-shadow: 0px 2px 6px 0px #79797929; */
  /* to align with scrollbar space in the list */
  padding-right: 5px;
}
.sortIcon {
  flex-grow: 0;
  color: #86bdd5;
  font-size: 1em;
  cursor: pointer;
  margin-left: 1em;
  border-radius: 3px;
  padding: 3px;
}

.sortIcon:hover {
  color: #fff;
  background-color: #0075a7;
  border-radius: 3px;
  padding: 3px;
}

.sortIcon:active {
  color: #fff;
  background-color: #025d85;
  border-radius: 2px;
}

.openSortIcon {
  color: #fff;
  background-color: #0075a7;
  border-radius: 3px;
    padding: 3px;
}
.tableHeader::-webkit-scrollbar {
  width: 4px !important;
  height: 0 !important;
}

.tableHeader > div {
  padding: 0 11px;
  box-sizing: border-box;
  overflow: hidden;
}
.headerWithMenu {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.headerWithMenu.ellipsisText {
  flex-grow: 1;
}
.ellipsisText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* ellipis popover -------------------------- */

.popoverPaper {
  color: white;
  background-color: grey;
  font-size: 11.8px;
  padding: 3px 6px;
}

.popover {
  pointer-events: none;
}
/* ------------------------------------------ */
