.listWrapper ul {padding: 0;}
.listWrapper li {
    background: #fff;
    border: 1px solid #b7b4b4;
    padding: 10px;
    border-radius: 3px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    align-items: flex-start;
}
.listWrapper li span{font-size: 12px;}
.listWrapper .bottomContent {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
}