.table {
  width: 100%;
  color: #707070;
  font-size: 12px;
  border: 0px;
}

.table tr:nth-child(even) {
  background: #fff;
}
.table tr:nth-child(odd) {
  background: #d8d8d845;
}

.table th {
  font-weight: 300;
  background: #fff;
  padding: 0px 0px 10px 0px;
  border: 0px;
}

.table td,
th {
  padding: 7px 0px;
  border: 0px;
}

.table tr th {
  position: sticky;
  top: 0;
  background: #fff;
}
