.projectName {
    color: #146DA2
}

.terminate {
    color: #F17C7C
}

.row{display: flex; flex-wrap: wrap;    background: #FFFBE6;
    padding: 10px 15px;}
.formGroup{flex: 0 0 33.333333%;display: flex;font-size: 14px;    margin: 10px 0;}
.formGroup label {color: #707070;font-weight: bold;margin-right: 5px;}
.formGroup p {margin: 0;}
.infoWrapper{
    color:#707070;
    height: 100%;
}
.infoWrapper h4{margin-top: 0;}
.blockContainer{ padding: 20px 15px 0;}
table td, table th {
    font-size: 14px;
    width: 140px;
    text-align: left;
}

@media all and (max-width:1100px){
    .formGroup{flex: 0 0 50%;}
}