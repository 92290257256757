.processDetailsView {
  width: 75%;
  margin-left: 1em;
  display: flex;
  flex-direction: column;
}

.processHeader {
  display: flex;
  align-items: center;
  height: 3em;
  margin-bottom: 0.5em;
}

.processContent {
  display: flex;
  align-items: center;
  height: 100%;
  max-height: calc(100% - 7em);
}

.content {
  flex-grow: 1;
}

.col1 {
  width: 25%;
}

.col3 {
  width: calc(75% - 1em);
  margin-left: 1em;
}

.tabsContainer {
  display: flex;
  align-items: center;
  height: 3em;
  min-height: 3em;
  margin-bottom: 0.5em;
}

.actionBar {
  flex-grow: 0;
  height: auto;
  margin: 0.7em 0;
  padding: 3.8px 0;
  box-sizing: border-box;
}

.formRow {
  display: flex;
  align-items: center;
  font-size: 13px;
  justify-content: space-between;
  flex-grow: 1;
  margin: 0 5px;
}

.label {
  color: #707070;
  display: flex;
  flex-grow: 1;
}

.formGroupRow{
  display: flex;
    padding: 5px 5px;
}
.select{
  display: flex;
    flex-grow: 4;
}

.processDetails {
  display: flex;
  flex-direction: column;
  height: calc(100% - 7em - 3.5em);
  background: #fff;
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: auto;
}

.processDetailsTabsContainer {
  height: 3em;
  display: flex;
  align-items: center;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 4px;
}

.tabWrapper { 
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.5em;
  border-radius: 4px;
}

.selected {
  color: #146DA2;
}

.notSelected {
  color: #707070;
}

.popContainer {
  width: 470px;
  padding: 50px 40px;
  margin: 0 auto;
  border-radius: 0;
}
.popupBox {
  border-radius: 0;
  width: 470px !important;
}

.title {
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  display: block;
  margin: -16px -24px 10px -24px;
  padding: 12px;
  background: #F17C7C;
  display: flex;
  align-items: center;
}

.txt {
  padding-left: 12px;
  font-weight: 600;
  display: inline-block;
}

.popupContent {
  margin-top: -15px;
  margin-bottom: 5px;
}

.uploadExcelInput {
  position: absolute;
  z-index: 1000;
  opacity: 0;
  cursor: pointer;
  right: 0;
  top: 0;
  height: 100%;
  font-size: 24px;
  width: 100%;
}

.boxShadow {
  height: 4px;
  width: 100%;
  position: relative;
  margin-top: -3px;
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}