.display {
  height: 5em;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.iconContainer {
  border-radius: 50px;
  border: 1px solid #dddddd;
  text-align: center;
  display: table-cell;
  padding: 8px;
  width: 50px !important;
  height: 48px !important;
  line-height: 34px;
  box-sizing: border-box;
}

.personIcon {
  color: #707070;
  font-size: 1.8em;
}
.details {
  width: 80%;
  display: inline-block;
  padding: 0.5em;
  color: #707070;
}
.name {
  display: block;
  font-weight: bold;
}
.email {
  display: block;
  font-weight: 400;
  font-size: 0.8em;
}
.logoutButton {
  margin-top: 1.5em;
  text-transform: none;
  padding: 0 7px 0 10px;
  color: white;
  font-size: 11px;
  background-color: #608fc9;
  border: solid 1px #608fc9;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  height: 2.5em;
  font-weight: 400;
}
.logoutButton:hover {
  color: #707070;
  border: 1px solid #707070;
  padding: 0 7px 0 10px;
  background-color: #fff;
}
