.button {
  font-weight: 600;
  font-size: 17px;
  height: 36px;
  padding: 0px 40px;
  border: none;
  outline: none;
  border-radius: 4px;
  box-shadow: 1px 3px 3px #00000029;
  text-transform: none;
}

.primaryButton {
  background: #59a385;
  color: #fff;
}

.primaryButton:hover {
  background: #488f71;
  color: fff;
}

.defaultButton {
  background: #d8d8d8;
  color: #707070;
}

.secondaryButton {
  background: #fff;
  color: #707070;
}

.disabledButton {
  box-shadow: none;
  color: #fff;
  background: #d8d8d8;
}

.tertiaryButton {
  background: #faa023;
  /* background: #ffa221; */
  color: #fff;
}

.tertiaryButton:hover {
  background: #d6891c;
}
