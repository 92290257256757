.container {
  padding: 1em;
  background: #FFFBE6;
}

.listWrapper ul {
  padding: 0;
}

.listWrapper li {
  background: #fff;
  border: 1px solid #b7b4b4;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  align-items: flex-start;
}

.listWrapper li span {
  font-size: 12px;
}

.listWrapper .bottomContent {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}
.listWrapper .topContentRight {
  margin-left: 50em;
  display: flex;
  width: 100%;
  justify-content: right;
  font: bold;
  font-size: 13px;
  float: right;
  width: 100px;
}

.listWrapper .topContentLift {
  display: flex;
  justify-content: space-between;
  float: left;
  width: 150px;
}
