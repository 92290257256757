.header {
  border-bottom: 1px solid #e8eef6;
  height: 52px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 30px 2px 30px;
  box-sizing: border-box;
  background: #fff;
  z-index: 100 !important;
  box-shadow: 0px 3px 6px #00000029;
}

.leftContainer {
  display: flex;
  align-items: center;
}

.logo {
  float: left;
  height: 2.5em;
  padding-top: 2px;
  border-right: 1px solid #e8eef6;
  padding-right: 20px;
  object-fit: cover;
  object-position: left top;
}

.appName {
  display: flex;
  align-items: center;
}

.appNameButton {
  color: #707070;
  text-transform: none;
  font-size: 14px;
  font-weight: 400;
  margin-left: 1em;
  margin-right: 3.5em;
  letter-spacing: 0;
  margin-top: 2px;
}

.arrowBackIcon {
  color: #e8eef6;
  opacity: 1;
  width: 25px;
  height: 25px;
  background: #608fc9;
  border-radius: 20px;
}
.rightContainer {
  text-align: right;
  width: auto;
  display: flex;
  align-items: center;
}
.appTitle {
  display: inline-block;
  font-weight: 500;
  margin-left: 5px;
  font-size: 17px;
  color: #0275a7;
}

.esakhaContainer {
  border-left: 1px solid #e8eef6;
  padding: 4px 0px 4px 15px;
  display: inline-block;
  width: 100%;
}
.esakhaLogo {
  width: 100%;
  max-width: 120px;
  object-fit: cover;
  object-position: left top;
}

.homeLink {
  display: inline-block;
}
.titleBottom {
  color: #707070;
  font-size: 1em;
  display: block;
  font-weight: 100;
}
.testTitle {
  color: #ff000020;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  width: 30%;
  left: 35%;
  position: absolute;
  top: 3px;
}

.testTitle strong {
  font-size: 28px;
  display: block;
}
.topIcons {
  margin-right: 15px;
  color: #2e75b5;
  display: flex;
  align-items: center;
}

.topIcons svg {
  margin-right: 12px;
  cursor: pointer;
  width: 1.1em;
  height: 1.1em;
}
.icDesable {
  color: #d6d6d6;
}

.iconButton {
  height: 2em;
  min-width: 2em;
}
