.progressBarr {
  display: flex;
  padding: 4px !important;
  background: 0% 0% no-repeat rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;
  border-radius: 4px;
  opacity: 1;
  flex-grow: 1;
  margin-left: 10px;



}

#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: #455A64;
  padding-left: 0px;
  margin-top: 14px;


}

#progressbar li {
  list-style-type: none;
  width: 8.66%;
  float: left;
  position: relative;
  font-weight: 500;
  font-size: 12px;
  z-index: 0;
}

#progressbar #step1:before {
  content: "";
}

#progressbar #step2:before {
  content: "";
}

#progressbar #step3:before {
  content: "";
}

#progressbar #step4:before {
  content: "";

}

#progressbar #step5:before {
  content: "";
}

#progressbar #step6:before {
  content: "";
}

#progressbar #step7:before {
  content: "";
}

#progressbar #step8:before {
  content: "";
}

#progressbar #step9:before {
  content: "";
}

#progressbar #step10:before {
  content: "";
}

#progressbar #step11:before {
  content: "";
}

#progressbar li:before {
  width: 10px;
  height: 10px;
  line-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  background: #455A64;
  border-radius: 50%;
  margin: auto;
  color: #fff;
}

#progressbar li:after {
  content: '';
  width: 100%;
  height: 2px;
  background: #455A64;
  position: absolute;
  left: 0;
  top: 5px;
  z-index: 0;
  font-weight: bold;
  font-size: 12px;
  color: #4e4e4e;
  opacity: 1;
}

/* #progressbar li:last-child:after {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  left: -45%
} */

#progressbar li:after {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  position: absolute;
  left: 55%
}

 /* #progressbar li:nth-child(10):after {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  position: absolute;
  left: -45%
}  */

#progressbar li:last-child:after {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  left: -50px;
}
#progressbar .lastC:last-child:after {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  left: -60px !important;
}

#progressbar li:first-child:after {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

#progressbar li.active:before,
#progressbar li.active:after {
  background: #146DA2
}

@media (min-width: 768px) {
  .block {
    display: block !important;
  }
}

.textCenter {
  text-align: center !important;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.my-5 {
  margin-bottom: 3rem !important;
}

.master {
  width: 100%;

}

.bg{

}

.bg:before {
  content: "";
  background: #F17C7C !important;
}

.bg>div {
  color: #F17C7C !important;
}