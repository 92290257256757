.container {
    height: 100%;
    margin-top: 0.8em;
    display: flex;
    flex-direction: column;
  }
  .uploadFileInput {
    position: absolute;
    z-index: 1000;
    opacity: 0;
    cursor: pointer;
    right: 0;
    top: 0;
    height: 100%;
    font-size: 0;
    width: 100%;
    cursor: pointer;
  }
  
  .select {
    color: #707070;
    font-weight: 500;
    font-size: 1em;
    border-radius: 2px;
    display: inline-block;
    border: none;
    outline: none !important;
    box-shadow: none !important;
   
  }
  .select:hover {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
  }
  .dateInput {
    width: 61%;
    box-sizing: border-box;
    outline: none;
    border: 0;
    font-size: 0.9em;
  }
  .smallBtn {
    font-weight: 500;
    color: #3A8AFA;
    font-size: 0.8em;
    margin: 0 10px;
    cursor: pointer;
    text-transform: capitalize;
    overflow-x: hidden;
  }
  .formContainer {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000027;
    opacity: 1;
    /* padding: 1.5em 0.5em 0 0.5em; */
    padding: 0.5em 0.5em 0 0.5em;
    border-radius: 4px;
    margin-top: 0.8em;
    flex-grow: 1;
    overflow: auto;
  }
  .deleteIcon{
    color: #3C83CB;
    font-size: 20px;
    cursor: pointer;
  }
  
  .formGroup {
    padding: 1.5em
  }
  
  .formGroupRow {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 1em 0;
    column-gap: 1em;
  }
  .myclass {
    width: 100% !important;
  }
  
  .projectInfoFormGroupRow {
    grid-template-columns: repeat(3, 27%);
  }
  
  .actionButton {
    font-size: 0.7em;
    padding: 0.5em 0.7em;
    letter-spacing: 1.25px;
    margin-right: 1em;
    text-transform: uppercase;
  }
  .formRow {
    display: flex;
    align-items: center;
    font-size: 14px;
    width: 100%;
    gap: 0.5em;
    justify-content: space-between;
    min-width: 33.33%;
    margin-bottom: 21px;
  }
  
  .label {
    font-size: 14px;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    margin-right: 10px;
    width: 40%;
  }
  
  .textInput {
    text-align: left;
    font-weight: 500;
    font-size: .9em;
  }
  
  .helperText {
    margin-left: 2px;
    margin-right: 0;
  }
  
  .select {
    color: #707070;
    font-weight: 500;
    font-size: 14px;
    border-radius: 2px;
    display: inline-block;
    border: none;
    outline: none !important;
    box-shadow: none !important;
    
  }
  .select:hover {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
  }
  
  .sel1 {
    width: 60%;
  }
  
  
  
  .projectMilestoneHeaderTitle {
    text-align: left;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    font-size: 0.8em;
    margin: 0;
  }
  
  .projectMilestoneformGroupRow {
    margin: 0.7em;
    align-items: center;
  }
  
  .actionBar {
    flex-grow: 0;
    height: auto;
    margin: 0.7em 0;
    width: 100%;
    padding: 3.8px 0;
    box-sizing: border-box;
  }
  
  .actionButton {
    font-size: 0.7em;
    padding: 0.5em 0.7em;
    letter-spacing: 1.25px;
    margin-right: 1em;
    text-transform: uppercase;
  }
  
  .saveAsDraftButton {
    color: #fff;
    background: #FFA222;
  }
  
  .saveAsDraftButton:hover {
    background: #e28404;
  }
  
  .resetButton {
    color: #fff;
    background: #C96B6B;
  }
  
  .resetButton:hover {
    background: #b45a5a;
  }
  
  .primaryActionButton {
    float: right;
    margin-right: 0;
    font-size: 0.7em;
    padding: 0.5em 0.7em;
  }
  
  .primaryActionButton:active {
    background: #488f71;
  }
  
  .transparentButton {
    color: #2E75B5;
    background: transparent;
    box-shadow: none;
  }
  
  .transparentButton:active {
    background: transparent;
  }
  
  .popContainer {
    width: 470px;
    padding: 50px 40px;
    margin: 0 auto;
    border-radius: 0;
  }
  .popupBox {
    border-radius: 0;
    width: 470px !important;
  }
  
  .title {
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    display: block;
    margin: -16px -24px 10px -24px;
    padding: 12px;
    background: #F17C7C;
    display: flex;
    align-items: center;
  }
  
  .txt {
    padding-left: 12px;
    font-weight: 600;
    display: inline-block;
  }
  
  .content {
    margin-top: -15px;
    margin-bottom: 5px;
  }
  
  .tableView {
    position: relative;
    overflow: hidden;
  }
  .tableContainerClassName{min-height: 200px;height: 100%;}
  .actionBar {
    flex-grow: 0;
    height: auto;
    margin: 0.7em 0;
    width: 100%;
    padding: 3.8px 0;
    box-sizing: border-box;
  }
  .actionButton {
    font-size: 0.7em;
    padding: 0.5em 0.7em;
    letter-spacing: 1.25px;
    margin-right: 1em;
    text-transform: uppercase;
  }
  .resetButton {
    color: #fff;
    background: #C96B6B;
  }
  
  .resetButton:hover {
    background: #b45a5a;
  }
  .primaryActionButton {
    float: right;
    margin-right: 0;
    font-size: 0.7em;
    padding: 0.5em 0.7em;
  }
  
  .primaryActionButton:active {
    background: #488f71;
  }
  .error {
    color: #FFA222;
    font-size: 14px;
  }
  .hoverText{
    background: #707070;
    top: 40px;
    color: #fff;
    font-size: 14px;
    padding: 2px 8px;
    border-radius: 3px;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    height: auto;
    box-shadow: 0px 4px 5px rgb(0 0 0 / 30%);
  }
  .disableInput{
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 9px 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: #F0F0F0;
    min-height: 18px
  }
  .error{
    color:#FFA222;
    /* position: absolute; */
    left: 40%;
    bottom: -20px;
    margin-left: 10px;
    font-size: 14px;
    text-align: center;
    margin-left: 58px;
  
  }
  .errormsg{color:#FFA222;font-size: 14px;}
  .row{
    display: block;
    /* width: 100%; */
    margin-bottom: 18px;
  }
  .formTitle{
    width: 100%;
    background-size: 100% 10px;
    font-size: 16px;
    height: 10px;
    font-weight: 600;
    background: #accdf2;
    margin-top: -0.5em;
  }
  .formSpan{
    
    color: #707070;
    background: #fff;
    padding-right: 1em;
    margin-top: -9px;
    float: left;
    font-weight: bolder;
    font-size: 20px;
  }
  .reqired{
    color: #FFA222;
    font-size: 14px;
    float: right;
    text-align: left;
    margin: 9px;
  }
  .lableDiv{
    /* width: 40%; */
    /* margin-top: 9px; */
    /* margin-left: 20px; */
    margin-right: 20px;
    font-size: 14px;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    /* word-break: break-all; */
    /* height: 20px; */
  }
  .rowDiv{
    width: 100%;
    display: flex;
  }
  .formDiv{
    width: 100%;
    display: flex;
  }
  .sel2 {
    width: 100%;
  }
  .alignCenter {
    display: flex;
    align-items: center;
  }
  .VCBCAddFrom_lableDiv__3ugK8 span.red {
    color: red !important;
  }
  .scrollBar {
    overflow: auto !important;
  }
  .scrollBar .ReactVirtualized__Grid__innerScrollContainer {
    width: auto;
  }
  
  .scrollBar::-webkit-scrollbar {
    width: 9px !important;
    height: 9px !important;
  }
  
  .scrollBar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px #dddddd;
    border-radius: 1px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-top: 4px solid transparent;
  }
  
  .scrollBar::-webkit-scrollbar-thumb {
    background: #2162ac;
  }
  
  .scrollBar::-webkit-scrollbar-thumb:hover {
    background: #194c86;
  }