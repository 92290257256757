.container {
  max-height: 40vh;
  width: 15em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
  border-radius: 4px;
}

.searchArea {
  width: 100%;
  flex-grow: 0;
  margin-bottom: 1em;
}
.openSortIcon {
  color: #fff;
  background-color: #0075a7;
  border-radius: 3px;
    padding: 3px;
}
.sortIcon {
  flex-grow: 0;
  color: #86bdd5;
  font-size: 1em;
  cursor: pointer;
  margin-left: 1em;
  border-radius: 3px;
  padding: 3px;
}

.sortIcon:hover {
  color: #fff;
  background-color: #0075a7;
  border-radius: 3px;
  padding: 3px;
}

.filterArea {
  background: #fafafa;
  padding: 0.5em 0;
  flex-grow: 1;
  height: 50%;
  width: 100%;
  overflow: scroll;
}

.filterOption {
  display: flex;
  align-items: center;
  margin-top: 0.5em;
  background: #fafafa;
}

.filterOption > input {
  margin-right: 0.5em;
}

.filterOption > label {
  font-size: 14px;
  color: #4e4e4e;
}

.filterApplyButtons {
  margin-top: 1em;
  flex-grow: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.okButton {
  display: relative;
  padding-left: 0.5px;
  padding-right: 0.5px;
  min-width: 13%;
  width: 13%;
  height: 3%;
  margin: 0.5em;
  font-size: 0.8em;
  background-color: #82c1a7;
  color: #eff7ff;
}

.cancelButton {
  display: relative;
  font-size: 0.8em;
  height: 3.5%;
  margin-left: 1em;
  padding-left: 1px;
  padding-right: 1px;
  background: transparent;
  box-shadow: none;
  color: #2e75b5;
}

.scrollBar {
  composes: scrollBar from '../../../../cssModules/ScrollBar.module.css';
}
